import {
  getAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  User,
  Auth,
} from "firebase/auth";

import { db, storage, app } from "./firebase";
import {
  DocumentChange,
  getDocs,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { Logger } from "@/helpers/Logger";
import { useUserStore } from "@/stores/useUserStore";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getFirestore,
} from "firebase/firestore";
import { reactive, toRefs } from "vue";

let userRef: DocumentReference<DocumentData, DocumentData>;
let auth: Auth = null; // set in authCheck
// const auth = getAuth();
export { db, auth, storage };
export let tasksCollectionRef = null;
export let notesCollectionRef = null;
export let foldersCollectionRef = null;
export let contextsCollectionRef = null;
export let goalsCollectionRef = null;
export let subscriptionsCollectionRef = null;
export let checkoutSessionsCollectionRef = null;

const state = reactive<{
  user?: User;
  initialized: boolean;
  error: any;
}>({
  user: null,
  initialized: false,
  error: null,
});

export function useFirebaseAuth() {
  const authCheck = () => {
    return new Promise((resolve) => {
      if (!state.initialized) {
        auth = getAuth(app);
        const userStore = useUserStore();
        const { setUser } = userStore;

        auth.onAuthStateChanged(function (_user: User) {
          console.log(
            `firebase-auth onAuthStateChanged for user ${_user?.uid})}`
          );
          if (_user) {
            console.log("User is signed in:", _user);
          } else {
            console.log("No user is signed in.");
          }

          // resolve(_user);

          try {
            if (!_user) {
              console.log(`firebase-auth onAuthStateChanged sees no user`);
              userRef = null;
              tasksCollectionRef = null;
              notesCollectionRef = null;
              foldersCollectionRef = null;
              contextsCollectionRef = null;
              goalsCollectionRef = null;
              subscriptionsCollectionRef = null;
              checkoutSessionsCollectionRef = null;
              resolve(_user);
            }
            if (_user) {
              console.log(`firebase-auth onAuthStateChanged sees user`, _user);
              try {
                userRef = doc(db, "users", _user.uid);

                if (userRef instanceof DocumentReference) {
                  tasksCollectionRef = collection(userRef, "tasks");
                  notesCollectionRef = collection(userRef, "notes");
                  foldersCollectionRef = collection(userRef, "folders");
                  contextsCollectionRef = collection(userRef, "contexts");
                  goalsCollectionRef = collection(userRef, "goals");
                  subscriptionsCollectionRef = collection(
                    userRef,
                    "subscriptions"
                  );
                  checkoutSessionsCollectionRef = collection(
                    userRef,
                    "checkout_sessions"
                  );
                }
                console.log(
                  `firebase-auth onAuthStateChanged sets user`,
                  _user
                );
                state.user = _user;
                setUser(_user);
              } catch (error) {
                Logger.logError(
                  `Error creating userRef, collections refs and getting storage.`,
                  error
                );
                throw error;
              }
            }
            state.initialized = true;
            resolve(_user);
          } catch (error) {
            Logger.logError("error in authCheck", error);
            throw error;
          }
        });
      }
    });
  };
  return {
    authCheck,
  };
}

export async function getCollectionDocs(collectionName: string) {
  if (!state.user) throw new Error("Error: getCollection has no user");
  try {
    const userRef = doc(db, "users", state.user.uid);
    const collectionRef = collection(userRef, collectionName);
    const snapshot = await getDocs(collectionRef);
    return snapshot.docs;
  } catch (error) {
    Logger.logError(`Error in getCollection for ${collectionName}`, error);
    throw error;
  }
}

export function listenForCollectionChanges(
  collectionName: string,
  callback: {
    (changes: DocumentChange<DocumentData, DocumentData>[]): void;
    (arg0: DocumentChange<DocumentData, DocumentData>[]): void;
  }
) {
  if (!state.user)
    throw new Error("Error: listenForCollectionChanges has no user");
  try {
    const userRef = doc(db, "users", state.user.uid);
    const collectionRef = collection(userRef, collectionName);
    const unsubscribe = onSnapshot(collectionRef, (snap: QuerySnapshot) => {
      const changes = snap.docChanges();
      if (changes.length > 0) callback(changes);
    });
    return unsubscribe;
  } catch (error) {
    Logger.logError(
      `Error in listenForCollectionChanges for ${collectionName}`,
      error
    );
    throw error;
  }
}
