import { Constants } from "@/constants/constants";
import {
  db,
  getCollectionDocs,
  goalsCollectionRef,
  listenForCollectionChanges,
} from "@/firebase/firebase-auth";
import { GoalObject } from "@/models/GoalModel";
import {
  collection,
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { defineStore } from "pinia";
import { v4 as generateUUID } from "uuid";

import { Logger } from "@/helpers/Logger";
import { Sorter } from "@/sorters/Sorter";
import { useUserStore } from "./useUserStore";

let firebaseGoalsUnsubscribe: { (): void; (): void } | null = null;

export const useGoalsStore = defineStore("goalsStore", {
  state: () => ({
    goals: Array<GoalObject>(),
    isLoadingGoals: false,
    haveGoalsLoaded: false,
    mostRecentId: "",
    areFirebaseGoalsSubscribed: false,
  }),

  /** Getters should be fat arrow functions unless they need to call other getters. That requires the use of this, which is only available in non-arrow functions.
   *
   */
  getters: {
    getGoals: (state) => {
      return state.goals ?? [];
    },
    goalFromId:
      (state) =>
      (id: string): GoalObject | undefined => {
        if (state.goals?.length > 0) {
          for (let i = 0; i < state.goals.length; i += 1) {
            if (state.goals[i].id === id) {
              return state.goals[i];
            }
          }
        }
        return undefined;
      },
    goalFromTitle:
      (state) =>
      (title: string): GoalObject | undefined => {
        const candidates =
          state.goals && state.goals.filter((f) => f.title === title);
        return (candidates && candidates[0]) || undefined;
      },

    allGoals: (state) => {
      return state.goals ?? [];
    },

    allGoalsCount: (state) => {
      return state.goals?.length || 0;
    },
  },
  actions: {
    subscribeToFirestoreGoals() {
      return new Promise<GoalObject[]>((resolve, reject) => {
        if (!goalsCollectionRef) {
          reject(new Error(`subscribeToFirestoreGoals has no collection ref`));
          return;
        }
        try {
          firebaseGoalsUnsubscribe = onSnapshot(
            goalsCollectionRef,
            async (snap: QuerySnapshot) => {
              try {
                if (this.haveGoalsLoaded) {
                  const changes = snap.docChanges();
                  if (!Array.isArray(changes)) return;
                  if (changes.length === 0) return;
                  changes.forEach((change) => {
                    this.processFirestoreChange(change);
                  });
                  this.goals = Sorter.copyAndSortGoals(this.goals);
                  resolve(this.goals);
                  return;
                }

                const docs = snap.docs;
                if (!Array.isArray(docs)) {
                  console.log(
                    "subscribeToFirestoreGoals snapshot sees docs are not an array"
                  );
                  reject(
                    new Error(
                      "subscribeToFirestoreGoals snapshot sees docs are not an array"
                    )
                  );
                }
                if (docs.length === 0) {
                  console.log(
                    "subscribeToFirestoreContexts snapshot sees there are no docs"
                  );
                  this.goals = [];
                }

                const converted = this.convertFirestoreDocs(docs);
                this.goals = Sorter.copyAndSortGoals(converted);
                this.haveGoalsLoaded = true;
                this.areFirebaseGoalsSubscribed = true;
                resolve(this.goals);
                return;
              } catch (innerError) {
                Logger.logError(
                  `Error in firebase goals onSnapshot, rejecting promise`,
                  innerError
                );
                reject(innerError);
              }
            }
          );
          this.haveGoalsLoaded = true;
          this.areFirebaseGoalsSubscribed = true;
        } catch (error) {
          Logger.logError(
            `Error in firebase goals onSnapshot, rejecting promise`,
            error
          );
          reject(error);
        }
      });
    },
    processFirestoreChanges(
      changes: DocumentChange<DocumentData, DocumentData>[]
    ) {
      for (const change of changes) {
        this.processFirestoreChange(change);
      }
      this.goals = Sorter.copyAndSortGoals(this.goals);
      console.log(
        `after processFirestoreChanges we now have ${this.goals.length} goals`
      );
    },
    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      const data = change.doc.data();
      const id = change.doc.id;
      const goal = new GoalObject({ ...data, id: id });
      if (change.type === "added") this.processFirestoreAdded(goal);
      else if (change.type === "modified") this.processFirestoreModified(goal);
      else if (change.type === "removed") this.processFirestoreRemoved(goal);
    },

    processFirestoreAdded(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id);
      if (!exists) this.goals.push(goal);
    },

    processFirestoreModified(goal: GoalObject) {
      goal.modified = new Date();
      const goalIndex = this.goals.findIndex(
        (t: GoalObject) => t.id == goal.id
      );
      if (goalIndex < 1) return;

      this.goals.splice(goalIndex, 1);
      this.goals.push(goal);
    },

    processFirestoreRemoved(goal: GoalObject) {
      const goalIndex = this.goals.findIndex(
        (t: GoalObject) => t.id == goal.id
      );
      if (goalIndex < 1) {
        return;
      }
      this.goals.splice(goalIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      const result = Array<GoalObject>();
      docs.forEach((doc: any) => {
        const goal = this.convertFirestoreDoc(doc);
        if (goal?.title) result.push(goal);
      });
      return result;
    },

    convertFirestoreDoc(doc: any) {
      const data = doc.data();
      const id = doc.id;
      return new GoalObject({ ...data, id: id });
    },

    async loadGoals() {
      try {
        console.log("loadGoals is starting");
        Logger.log(`loadGoals is starting`);

        console.log(`loadGoals is calling getCollectionDocs for goals`);
        const docs = await getCollectionDocs("goals");
        console.log(`loadGoals has got ${docs.length} docs`);

        const fetched = this.convertFirestoreDocs(docs);
        Sorter.sortGoalsInPlace(fetched);

        this.$patch({ goals: fetched, haveGoalsLoaded: true });

        console.log(
          `loadGoals has converted ${this.goals.length} goals and starts listening for changes`
        );
        this.haveGoalsLoaded = true;
        this.listenForFirestoreGoalChanges();
        console.log(
          `loadGoals is listening for changes. There are currently ${this.goals.length} goals.`
        );
      } catch (error) {
        Logger.logError("Error in loadGoals", error);
        throw error;
      }
    },

    listenForFirestoreGoalChanges() {
      firebaseGoalsUnsubscribe = listenForCollectionChanges(
        "goals",
        (changes: DocumentChange<DocumentData, DocumentData>[]) => {
          this.processFirestoreChanges(changes);
        }
      );
    },

    unsubscribeGoals() {
      if (firebaseGoalsUnsubscribe) {
        firebaseGoalsUnsubscribe();
        this.haveGoalsLoaded = false;
      }
    },
    clearGoals() {
      this.goals = [];
      this.haveGoalsLoaded = false;
    }, // clearGoals

    addGoalToStateAndSort(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id);
      if (exists) return;
      this.goals.push(goal);
      Sorter.sortGoalsInPlace(this.goals);
    },

    addGoalToState(goal: GoalObject) {
      const exists = !!this.goalFromId(goal.id);
      if (exists) return;
      this.goals.push(goal);
    },

    async addGoal(goal: GoalObject) {
      if (!goal.id) goal.id = generateUUID();
      this.addGoalToState(goal);
      this.writeGoalToFirestore(goal);
    },

    async writeGoalToFirestore(goal: GoalObject) {
      const userStore = useUserStore();
      if (!userStore.user?.uid) return false;
      const userDocRef = doc(db, "users", userStore.user.uid);
      const collectionRef = collection(userDocRef, "goals");
      const docRef = doc(collectionRef, goal.id);
      const obj = goal.copyToFirestoreObject();
      await setDoc(docRef, obj);
    },

    async editGoal(goal: GoalObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;

        goal.modified = new Date();

        const goalIndex: number = this.goals.findIndex(
          (t: GoalObject) => t.id == goal.id
        );

        if (goalIndex < 0) {
          return;
        }

        this.removeGoalFromStateAtIndex(goalIndex);
        this.goals.push(goal);

        await this.writeGoalToFirestore(goal);
      } catch (error: any) {
        Logger.logError("Error in `editGoal", error);
        throw error;
      }
    },

    makeDefaultGoal() {
      const goal = new GoalObject({});
      return goal;
    },

    removeGoalFromStateAtIndex(index: number) {
      this.goals.splice(index, 1);
    },

    removeGoalWithIdFromState(id: string) {
      const foundIndex = this.goals.findIndex((goal) => goal.id === id);
      if (foundIndex >= 0) this.removeGoalFromStateAtIndex(foundIndex);
    },

    async removeGoal(id: string) {
      await this.removeGoals([id]);
    },

    async removeGoals(ids: string[]) {
      if (!ids) return;
      const userStore = useUserStore();
      for (const id of ids) {
        const goalIndex: number = this.goals.findIndex(
          (t: GoalObject) => t.id == id
        );
        this.removeGoalFromStateAtIndex(goalIndex);
      }
      try {
        let batch = writeBatch(db);
        let itemsInBatch = 0;

        for (const id of ids) {
          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "goals");
          const docRef = doc(collectionRef, id);

          batch.delete(docRef);
        }

        await batch.commit();
      } catch (error: any) {
        Logger.logError("Error in removeGoals", error);
        throw error;
      }
    },

    unsubscribeFirestore() {
      if (firebaseGoalsUnsubscribe) {
        firebaseGoalsUnsubscribe();
        this.areFirebaseGoalsSubscribed = false;
      }
    },
  },
});
