import { TaskObject } from "../TaskObject";
import { DateFilterOption } from "../filters/DateFilterOption";
import { Filter } from "./Filter";
import startOfDay from "date-fns/startOfDay";
import {
  addDays,
  addMonths,
  addYears,
  isAfter,
  isBefore,
  isSameDay,
  isSameWeek,
  isToday,
} from "date-fns";
import { Logger } from "@/helpers/Logger";

/**
 * Filters tasks by start date, using friendly terms like 'overstart', 'today' and 'undated'
 */
export class ImportedDateFilter extends Filter {
  public get debugDescription(): string {
    return `Imported date filter: ${this.option}`;
  }
  option: DateFilterOption;
  constructor(option: DateFilterOption) {
    // console.log(`Constructing imported date filter for date option ${value}`)
    super(option);
    this.option = option;
  }

  /**
   * Returns true if a task matches the value expressed as Today, Yesterday or whatever
   * @param task - the task
   *
   * @remarks
   * The value for comparison is set in the constructor
   */
  public isFiltered(task: TaskObject): boolean {
    let result = false;
    const now = new Date();
    const today = startOfDay(now);
    const imported = task.imported;

    if (!imported) {
      return this.option === DateFilterOption.Undated;
    }

    switch (this.option) {
      case DateFilterOption.Today:
        Logger.log(
          `ImportedDateFilter is looking for tasks imported today ${today}`
        );
        result = isToday(imported); //  imported > today && imported < addDays(today, 1);
        Logger.log(
          `ImportedDateFilter returns ${result} for task ${JSON.stringify(
            task
          )}, which was imported ${today}`
        );
        break;
      case DateFilterOption.Yesterday:
        result = !!imported && isSameDay(imported, addDays(today, -1));
        break;
      case DateFilterOption.Week:
        result = !!imported && isAfter(today, addDays(today, -7));
        break;
      case DateFilterOption.Month:
        result = !!imported && isAfter(today, addMonths(today, -1));
        break;
      case DateFilterOption.Quarter:
        result = !!imported && isAfter(today, addMonths(today, -3));
        break;
      case DateFilterOption.Year:
        result = !!imported && isAfter(today, addYears(today, -1));
        break;
      case DateFilterOption.Older:
        result = !!imported && isBefore(today, addYears(today, -1));
        break;
    }

    return result;
  }

  public get description() {
    switch (this.option) {
      case DateFilterOption.Undated:
        return "not imported";
      case DateFilterOption.Today:
        return "imported today";
      case DateFilterOption.Yesterday:
        return "imported yesterday";
      case DateFilterOption.Week:
        return "imported in past week";
      case DateFilterOption.Month:
        return "imported in past month";
      case DateFilterOption.Quarter:
        return "imported in past 3 months";
      case DateFilterOption.Year:
        return "imported in past year";
      case DateFilterOption.Older:
        return "imported more than a year ago";
      default:
        return undefined;
    }
  }
}
