import { Constants } from "@/constants/constants";
import {
  collection,
  doc,
  DocumentChange,
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  writeBatch,
} from "firebase/firestore";

import {
  db,
  getCollectionDocs,
  listenForCollectionChanges,
  tasksCollectionRef,
} from "@/firebase/firebase-auth";
import { TaskObject } from "@/models/TaskObject";
import { AllFilter } from "@/models/filters/AllFilter";
import { IsCompleteFilter } from "@/models/filters/IsCompleteFilter";
import { defineStore, storeToRefs } from "pinia";

import { NotFilter } from "@/models/filters/NotFilter";
import { PriorityFilter } from "@/models/filters/PriorityFilter";

import { Logger } from "@/helpers/Logger";
import { RepeatManager } from "@/helpers/RepeatManager";
import { SortDescriptor, Sorter } from "@/sorters/Sorter";
import { ContextObject } from "@/models/ContextModel";
import { FolderObject } from "@/models/FolderModel";
import { GoalObject } from "@/models/GoalModel";
import { Priority } from "@/models/Priority";
import { TaskGroup } from "@/models/TaskGroup";
import { DateFilterOption } from "@/models/filters/DateFilterOption";
import { Filter } from "@/models/filters/Filter";
import { StartDateFilter } from "@/models/filters/StartDateFilter";
import { TextFilter } from "@/models/filters/TextFilter";
import { useFiltersStore } from "@/stores/useFiltersStore";
import { useSettingsStore } from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import Papa from "papaparse";
import TurndownService from "turndown";
import { v4 as generateUUID } from "uuid";
import { useContextsStore } from "./useContextsStore";
import { useFeatureFlagsStore } from "./useFeatureFlagsStore";
import { useFoldersStore } from "./useFoldersStore";
import { useGoalsStore } from "./useGoalsStore";
import { useUserStore } from "./useUserStore";
import { TaskSorter } from "@/sorters/TaskSorter";
import { TaskSortDescriptor } from "@/sorters/TaskSortDescriptor";
import { PropertyUtils } from "@/models/PropertyUtils";
import { SortDirection } from "@/models/SortDirection";
import { Property } from "@/models/Property";
import { differenceInDays, formatISO, addDays } from "date-fns";
import { TaskGrouper } from "@/sorters/TaskGrouper";

let firebaseTasksUnsubscribe = null;

const getRepeatParameterFromICal = (iCal: string, key: string): string => {
  let result = "";
  const index = iCal.indexOf(key);
  if (index < 0) {
    return "";
  }
  const remainderIndex = index + key.length + 1;
  const terminatorIndex = iCal.indexOf(";", remainderIndex);
  if (terminatorIndex < 0) {
    result = iCal.substring(remainderIndex);
    return result.trim();
  }
  result = iCal.substring(remainderIndex, terminatorIndex);
  return result.trim();
};

export const useTasksStore = defineStore("tasksStore", {
  state: () => ({
    tasks: [] as TaskObject[],
    // isLoadingTasks: false,
    haveTasksLoaded: false,
    mostRecentId: "",
    isFirestoreSubscribed: false,
    csvHeaders: Array<string>(),
    csvFieldProperties: Array<string>(),
    csvTitleFieldName: "",
    importedCount: 0,
    importError: "",
    importAborted: false,
    isImportParsing: false,
    isImportProcessing: false,
    selectedTasks: [] as TaskObject[],
    openParents: [] as TaskObject[],
  }),

  getters: {
    getTasks: (state) => {
      return state.tasks ?? [];
    },
    getUser: () => {
      try {
        const userStore = useUserStore();
        return userStore.user;
      } catch (error) {
        Logger.logError("Error in tasks store getUser getter", error);
        throw error;
      }
    },

    taskFromId:
      (state) =>
      (id: string): TaskObject | undefined => {
        if (state.tasks?.length > 0) {
          for (let i = 0; i < state.tasks.length; i += 1) {
            if (state.tasks[i].id === id) {
              return state.tasks[i];
            }
          }
        }
        return undefined;
      },

    // taskWithIdExists: (state) => {
    //   (id: string) => {
    //     return !!state.tasks.find(item => item.id === id);
    //   }
    // },
    taskWithIdExists: (state) => (id) => {
      return !!state.tasks.find((item) => item.id === id);
    },

    allTasksCount(): number {
      return this.tasks?.length || 0;
    },

    countTasksFilteredBy() {
      const filters = useFiltersStore();
      const settings = useSettingsStore();
      return (filterId?: string): number => {
        const allTasks: TaskObject[] = this.tasks;
        if (allTasks.length < 1) {
          return 0;
        }

        if (useFeatureFlagsStore().newHamburgerMenuFlag) {
          let filter: Filter = filters.taskFilterFromId(
            filterId ?? Constants.ACTIVE_FILTER
          );
          if (filter) {
            switch (filterId) {
              case Constants.URGENCY_CRITICAL_NOW_FILTER:
                filter = new AllFilter("counting filter", [
                  filter,
                  filters.taskFilterFromId(Constants.ACTIVE_FILTER),
                ]);
                break;
              default:
                break;
            }
          }

          if (filter) {
            return allTasks.reduce((total, task) => {
              if (filter.isFiltered(task)) {
                total += 1;
              }
              return total;
            }, 0);
          }
          return allTasks?.length ?? 0;
        }

        const compound = new AllFilter("Counting task filter");

        if (filterId && filterId != Constants.ALL_TASKS_FILTER) {
          const filter = filters.taskFilterFromId(filterId);

          if (filter) {
            compound.add(filter);
          }
        }

        if (filterId != Constants.COMPLETED_FILTER && !settings.showCompleted) {
          compound.add(new IsCompleteFilter(false));
        }

        if (
          filterId != Constants.PRIORITY_NEGATIVE_FILTER &&
          !settings.showNegative
        ) {
          compound.add(
            new NotFilter(
              "Not negative",
              new PriorityFilter(Constants.PRIORITY_NEGATIVE)
            )
          );
        }

        if (
          filterId != Constants.SHOW_FUTURE_STARTS &&
          !settings.showFutureStarts
        ) {
          compound.add(
            new NotFilter(
              "Not future start",
              new StartDateFilter(DateFilterOption.Future)
            )
          );
        }

        return allTasks.reduce((total, task) => {
          if (compound.isFiltered(task)) {
            total += 1;
          }
          return total;
        }, 0);
      };
    },

    criticalTasksCount(): number {
      return this.countTasksFilteredBy(Constants.URGENCY_CRITICAL_NOW_FILTER);
    },

    activeTasksCount(): number {
      return this.countTasksFilteredBy();
    },

    completedTasksCount(): number {
      return this.countTasksFilteredBy(Constants.COMPLETED_FILTER);
    },

    todaysTasksCount(): number {
      return this.countTasksFilteredBy(Constants.DUE_TODAY_FILTER);
    },

    areTasksEqual() {
      return (task1: TaskObject, task2: TaskObject) => {
        return JSON.stringify(task1) == JSON.stringify(task2);
      };
    },

    taskIsSelected() {
      return (task: TaskObject) => {
        return !!this.selectedTasks.find(
          (item: TaskObject) => task.id === item.id
        );
      };
    },

    taskIsOpenParent() {
      return (task: TaskObject) => {
        return !!this.openParents.find(
          (item: TaskObject) => task.id === item.id
        );
      };
    },

    subtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        if (!this.tasks) {
          return [];
        }
        const id = task?.id;
        return this.tasks.filter((task: TaskObject) => {
          return !!task.parentId && task.parentId === id;
        });
      };
    },

    activeSubtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        if (!this.tasks) {
          return [];
        }
        const id = task?.id;
        return this.tasks.filter((subtask: TaskObject) => {
          return (
            !!subtask.parentId && subtask.parentId === id && !subtask.completed
          );
        });
      };
    },

    filteredSubtasksOfTask() {
      return (task: TaskObject): TaskObject[] => {
        const filteredTasks: TaskObject[] = this.filteredTasks;
        if (!filteredTasks) {
          return [];
        }
        const id = task?.id;
        return filteredTasks.filter((item) => item.parentId === id);
      };
    },

    hasSubtasks() {
      return (task: TaskObject) => {
        return !!this.tasks.find((t: TaskObject) => t.parentId === task.id);
      };
    },

    descendantsOfTaskWithId() {
      return (id: string) => {
        const tasks: TaskObject[] = this.tasks;
        const subtasks = tasks.filter((t: TaskObject) => t.parentId === id);
        if (!subtasks) {
          return [];
        }
        let result = new Array<TaskObject>();
        if (subtasks.length > 0) {
          for (let i = 0; i < subtasks.length; i += 1) {
            const sub: TaskObject = subtasks[i];
            result.push(sub);
            const descendantsOfSubtask = this.descendantsOfTaskWithId(sub.id);
            if (descendantsOfSubtask) {
              if (descendantsOfSubtask.length > 0) {
                result = result.concat(descendantsOfSubtask);
              }
            }
          }
          return result;
        }

        return result;
      };
    },

    ancestorIdsOfTaskWithId() {
      return (taskId: string): string[] => {
        const task = this.taskFromId(taskId);
        if (!task || !task.parentId) {
          return Array<string>();
        }

        const parentAncestorIds = this.ancestorIdsOfTaskWithId(task.parentId);
        if (parentAncestorIds.length > 0) {
          return [task.parentId, ...parentAncestorIds];
        }
        return [task.parentId];
      };
    },

    hasImportedTasks(): boolean {
      if (!this.tasks) return false;
      for (let i = 0; i < this.tasks.length; i++) {
        if (this.tasks[i].imported) return true;
      }
      return false;
    },

    tasksReadyToPurge(): TaskObject[] {
      let result = new Array<TaskObject>();

      for (let i = 0; i < this.tasks?.length; i++) {
        const task: TaskObject = this.tasks[i];
        if (task.completed) {
          if (!task.parentId) {
            result.push(task);

            const descendants = this.descendantsOfTaskWithId(task.id);
            if (descendants.length > 0) {
              result = result.concat(descendants);
            }
          }
        }
      }

      return result;
    },
    repeatDisplayString: () => (task: TaskObject) => {
      const intervalString = getRepeatParameterFromICal(
        task.repeat,
        "INTERVAL"
      );
      const daysString = getRepeatParameterFromICal(task.repeat, "BYDAY");
      const interval =
        intervalString.length == 0 ? 0 : parseInt(intervalString, 10);

      return (
        (!task.repeat && "Never") ||
        (task.repeat.includes("PARENT") && "With Parent") ||
        (task.repeat.includes("COMP") &&
          ((task.repeat.includes("DAILY") &&
            ((interval === 0 && "Tomorrow") || `Next ${interval} days`)) ||
            (task.repeat.includes("WEEKLY") &&
              ((interval === 0 && "Next week") || `Next ${interval} weeks`)) ||
            (task.repeat.includes("MONTHLY") &&
              ((interval === 0 && "Next month") ||
                `Next ${interval} months`)) ||
            (task.repeat.includes("YEARLY") &&
              ((interval === 0 && "Next year") || `Next ${interval} years`)) ||
            (task.repeat.includes("BYDAY") &&
              !!daysString &&
              ((daysString === "MO,TU,WE,TH,FR" && "Next weekday") ||
                (daysString === "SU,SA" && "Next weekend day"))))) ||
        // not COMP

        (task.repeat.includes("DAILY") &&
          ((interval === 0 && "Every day") || `Every ${interval} days`)) ||
        (task.repeat.includes("WEEKLY") &&
          ((interval === 0 && "Weekly") ||
            (interval === 2 && "Biweekly") ||
            `Every ${interval} weeks`)) ||
        (task.repeat.includes("MONTHLY") &&
          ((interval === 0 && "Every month") ||
            (interval === 3 && "Quarterly") ||
            (interval === 6 && "Semiannually") ||
            `Every ${interval} months`)) ||
        (task.repeat.includes("YEARLY") &&
          ((interval === 0 && "Every year") || `Every ${interval} years`)) ||
        (task.repeat.includes("BYDAY") &&
          !!daysString &&
          ((daysString === "MO,TU,WE,TH,FR" && "Weekdays") ||
            (daysString === "SU,SA" && "Weekends"))) ||
        task.repeat
      );
    },
    unfilteredTasks(): TaskObject[] {
      if (this.tasks.length === 0) {
        return [];
      }
      return this.tasks;
    },
    filteredTasks(): TaskObject[] {
      if (this.unfilteredTasks.length === 0) {
        return [];
      }

      return this.unfilteredTasks.filter((task) => this.isFiltered(task));
    },

    filteredTasksHiddenByParents(): TaskObject[] {
      if (this.unfilteredTasks.length === 0) {
        return [];
      }

      return this.unfilteredTasks.filter((task) =>
        this.isFilteredWithoutParent(task)
      );
    },

    finalFilter() {
      const filtersStore = useFiltersStore();
      const selectedFilter = filtersStore.selectedFilter;
      const { tasksSearchText } = storeToRefs(useSettingsStore());
      return tasksSearchText.value
        ? new AllFilter("Final Filter", [
            selectedFilter,
            new TextFilter(tasksSearchText.value),
          ])
        : selectedFilter;
    },

    isFiltered() {
      return (task: TaskObject) => {
        const filtersStore = useFiltersStore();
        const selectedFilter = filtersStore.selectedFilter;
        if (!selectedFilter.isFiltered(task)) return false;
        const settings = useSettingsStore();
        const searchText = settings.tasksSearchText;
        if (searchText) {
          const searchFilter = new TextFilter(searchText);
          return searchFilter.isFiltered(task);
        }
        return true;
      };
    },

    isFilteredWithoutParent() {
      return (task: TaskObject) => {
        if (task.parentId) {
          const parent = this.taskFromId(task.parentId);
          if (!!parent && this.isFiltered(parent)) return false;
        }

        return this.isFiltered(task);
      };
    },

    sortDescriptors() {
      const {
        groupBy,
        groupDirection,
        sort1By,
        sort1Direction,
        sort2By,
        sort2Direction,
        sort3By,
        sort3Direction,
      } = storeToRefs(useSettingsStore());

      const result = Array<SortDescriptor>();
      const asc = groupDirection.value === "ascending";
      if (groupBy.value) {
        const desc: SortDescriptor = {
          property: groupBy.value.toLowerCase(),
          ascending: asc,
        };
        result.push(desc);
      }

      const sort1: string = sort1By.value.toLowerCase();
      if (sort1) {
        const asc = sort1Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort1, ascending: asc };
        result.push(desc);
      }

      const sort2: string = sort2By.value.toLowerCase();
      if (sort2) {
        const asc = sort2Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort2, ascending: asc };
        result.push(desc);
      }

      const sort3: string = sort3By.value.toLowerCase();
      if (sort3) {
        const asc = sort3Direction.value === "ascending";
        const desc: SortDescriptor = { property: sort3, ascending: asc };
        result.push(desc);
      }

      return result;
    },

    sortedFilteredTasks() {
      return (hiddenByParents: boolean) => {
        if (hiddenByParents && !this.filteredTasksHiddenByParents) {
          return [];
        }
        if (!hiddenByParents && !this.filteredTasks) {
          return [];
        }
        const {
          groupBy,
          groupDirection,
          sort1By,
          sort1Direction,
          sort2By,
          sort2Direction,
          sort3By,
          sort3Direction,
        } = storeToRefs(useSettingsStore());
        const sortDescriptors = Array<TaskSortDescriptor>();
        if (groupBy.value) {
          const property = PropertyUtils.propertyFromString(groupBy.value);
          if (property && property != Property.None) {
            const direction =
              (groupDirection.value === "ascending" &&
                SortDirection.Ascending) ||
              SortDirection.Descending;
            const descriptor = new TaskSortDescriptor(property, direction);
            sortDescriptors.push(descriptor);
          }
        }

        if (sort1By.value) {
          const property = PropertyUtils.propertyFromString(sort1By.value);
          if (property && property != Property.None) {
            const direction =
              (sort1Direction.value === "ascending" &&
                SortDirection.Ascending) ||
              SortDirection.Descending;
            const descriptor = new TaskSortDescriptor(property, direction);
            sortDescriptors.push(descriptor);
          }
        }

        if (sort2By.value) {
          const property = PropertyUtils.propertyFromString(sort2By.value);
          if (property && property != Property.None) {
            const direction =
              (sort2Direction.value === "ascending" &&
                SortDirection.Ascending) ||
              SortDirection.Descending;
            const descriptor = new TaskSortDescriptor(property, direction);
            sortDescriptors.push(descriptor);
          }
        }

        if (sort3By.value) {
          const property = PropertyUtils.propertyFromString(sort3By.value);
          if (property && property != Property.None) {
            const direction =
              (sort3Direction.value === "ascending" &&
                SortDirection.Ascending) ||
              SortDirection.Descending;
            const descriptor = new TaskSortDescriptor(property, direction);
            sortDescriptors.push(descriptor);
          }
        }

        const whatToSort: TaskObject[] = hiddenByParents
          ? [...this.filteredTasksHiddenByParents]
          : [...this.filteredTasks];
        const sorter = new TaskSorter(sortDescriptors);
        return sorter.sort(whatToSort);
      };
    },

    groupedSortedFilteredTasks(): TaskGroup[] {
      const { groupBy, groupDirection } = storeToRefs(useSettingsStore());

      if (!this.tasks || this.tasks.length === 0) return [];

      const hiddenByParents = true;
      const tasks: TaskObject[] = this.sortedFilteredTasks(hiddenByParents);

      const property: Property = Property[groupBy.value];
      const direction: SortDirection = SortDirection[groupDirection.value];

      const grouper = new TaskGrouper(property, direction);
      const result = grouper.group(tasks);
      return result;
    },
  },
  actions: {
    listenForFirestoreTaskChanges() {
      // if (!tasksCollectionRef) {
      //   throw new Error(`listenForFirestoreTaskChanges has no collection ref`);
      // }

      firebaseTasksUnsubscribe = listenForCollectionChanges(
        "tasks",
        (changes: DocumentChange<DocumentData, DocumentData>[]) => {
          this.processFirestoreChanges(changes);
        }
      );
    },

    processFirestoreChanges(
      changes: DocumentChange<DocumentData, DocumentData>[]
    ) {
      for (const change of changes) {
        this.processFirestoreChange(change);
      }
      console.log(
        `after processFirestoreChanges we now have ${this.tasks.length} tasks`
      );
    },
    processFirestoreChange(change: DocumentChange<DocumentData, DocumentData>) {
      // Logger.log(`processFirestoreChange sees change type ${change.type}`);
      const data = change.doc.data();
      const id = change.doc.id;
      const task = TaskObject.fromFirestoreObject(data, id);
      if (change.type === "added") this.processFirestoreAdded(task);
      else if (change.type === "modified") this.processFirestoreModified(task);
      else if (change.type === "removed") this.processFirestoreRemoved(task);
    },

    processFirestoreAdded(task: TaskObject) {
      const exists = !!this.taskFromId(task.id);
      if (!exists) {
        Logger.log(`processFirestoreAdded adds task ${task.id}`);
        this.tasks.push(task);
      }
    },

    processFirestoreModified(task: TaskObject) {
      task.modified = new Date();
      const taskIndex = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      if (taskIndex < 1) {
        throw new Error(
          `unable to modify task ${task.title} as it can't be found`
        );
      }

      this.tasks.splice(taskIndex, 1);
      this.tasks.push(task);
    },

    processFirestoreRemoved(task: TaskObject) {
      const taskIndex = this.tasks.findIndex(
        (t: TaskObject) => t.id == task.id
      );
      if (taskIndex < 1) {
        Logger.logError(
          `unable to remove task ${task.title} as it can't be found`
        );
        return;
      }
      this.tasks.splice(taskIndex, 1);
    },

    convertFirestoreDocs(
      docs: QueryDocumentSnapshot<DocumentData, DocumentData>[]
    ) {
      try {
        const result = Array<TaskObject>();
        docs.forEach((doc: any) => {
          const task = this.convertFirestoreDoc(doc);
          result.push(task);
        });
        return result;
      } catch (error) {
        Logger.logError("error while converting firestore docs", error);
        throw error;
      }
    },

    convertFirestoreDoc(doc: any) {
      try {
        const data = doc.data();
        const id = doc.id;
        return TaskObject.fromFirestoreObject(data, id);
      } catch (error) {
        Logger.logError("error while converting firestore doc", error);
        throw error;
      }
    },

    async addWelcomeTask() {
      let note =
        "__Welcome to TaskAngel__  \nNow you can have more success at work 👍 and more fun at home 😎  \n";
      note += "\n\n---\n\n";
      note +=
        "This is your first task. To go back to your task list, tap the back button above. \n";
      note +=
        "For more help and support, please contact us at https://www.taskangel.com/contact";
      const welcomeTask = new TaskObject({
        title: "Welcome to TaskAngel!",
        note: note,
        priority: Priority.Medium,
      });

      await this.addTask(welcomeTask);
    },
    async loadTasks() {
      try {
        console.log("loadTasks is starting");
        Logger.log(`loadTasks is starting`);

        console.log(`loadTasks is calling getCollectionDocs for tasks`);
        const docs = await getCollectionDocs("tasks");
        console.log(`loadTasks has got ${docs.length} docs`);
        const fetchedTasks = this.convertFirestoreDocs(docs);
        this.$patch({ tasks: fetchedTasks, haveTasksLoaded: true });
        console.log(
          `loadTasks has converted ${this.tasks.length} tasks and starts listening for changes`
        );
        this.listenForFirestoreTaskChanges();
        console.log(
          `loadTasks is listening for changes. There are currently ${this.tasks.length} tasks.`
        );
        // return this.tasks;
        // if (this.isLoadingTasks) {
        //   Logger.logError(`Error: loadTasks sees tasks are already loading`);
        //   throw new Error(`Error: loadTasks sees tasks are already loading`);
        // }
        // if (this.haveTasksLoaded) {
        //   Logger.logError(`Error: loadTasks sees tasks have already loaded`);
        //   throw new Error(`Error: loadTasks sees tasks have already loaded`);
        // }
        // if (firebaseTasksUnsubscribe) {
        //   Logger.logError(
        //     `Error: loadTasks sees we are already subscribing to tasks - unsubscribing`
        //   );
        //   this.unsubscribeTasks();
        // }
        // const userStore = useUserStore();
        // if (!userStore.user) {
        //   Logger.logError(`loadTasks aborts because there is no user`);
        //   throw new Error(`loadTasks aborts because there is no user`);
        // }
        // try {
        //   // this.isLoadingTasks = true;
        //   Logger.log(`loadTasks is subscribing to Firestore tasks`);
        //   const result = await this.listenForFirestoreTaskChanges();
        //   Logger.log(`loadTasks has subscribed to Firestore tasks`);
        //   this.$patch({
        //     haveTasksLoaded: true,
        //     isLoadingTasks: false,
        //   });
        //   return result;
        // }
      } catch (error) {
        Logger.logError("Error in loadTasks", error);
        throw error;
      }
    },

    unsubscribeTasks() {
      if (firebaseTasksUnsubscribe) {
        firebaseTasksUnsubscribe();
        firebaseTasksUnsubscribe = null;
        this.haveTasksLoaded = false;
      }
    },

    clearTasks() {
      this.tasks = [];
      this.haveTasksLoaded = false;
    },

    selectTask(task: TaskObject) {
      if (this.selectedTasks.find((item: TaskObject) => task.id == item.id)) {
        return;
      }
      this.selectedTasks.push(task);
    },

    selectTasks(tasks: TaskObject[]) {
      this.selectedTasks = [...this.selectedTasks, ...tasks];
    },

    deselectTask(task: TaskObject) {
      this.selectedTasks = this.selectedTasks.filter(
        (item) => item.id != task.id
      );
    },

    async adoptSelectedTasksInto(newParent: TaskObject) {
      try {
        for (const t of this.selectedTasks) {
          if (t.id != newParent.id) t.parentId = newParent.id;
        }

        await this.saveTasks(this.selectedTasks);
        this.clearSelectedTasks();
      } catch (error) {
        Logger.logError("Error in useTasksStore.adoptSelectedTasksInto", error);
        throw error;
      }
    },

    clearSelectedTasks() {
      this.selectedTasks = [];
    },

    setParentOpen(parent: TaskObject) {
      if (this.openParents?.find((item: TaskObject) => parent.id == item.id)) {
        return;
      }
      this.openParents.push(parent);
    },

    setParentClosed(parent: TaskObject) {
      this.openParents = this.openParents.filter(
        (item: TaskObject) => item.id != parent.id
      );
    },

    async addTask(task: TaskObject) {
      try {
        task.id = task.id ?? generateUUID();
        await this.saveTask(task);
        await this.saveTaskToFirestore(task);
      } catch (error) {
        Logger.logError("Error in useTasksStore.addTask", error);
        throw error;
      }
    },

    async saveTask(task: TaskObject) {
      try {
        this.saveTaskToState(task);
        await this.saveTaskToFirestore(task);
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTask", error);
        throw error;
      }
    },

    async saveTaskToFirestore(task: TaskObject) {
      try {
        const userStore = useUserStore();
        if (!userStore.user?.uid) return false;
        const userDocRef = doc(db, "users", userStore.user.uid);
        const collectionRef = collection(userDocRef, "tasks");
        const docRef = doc(collectionRef, task.id);
        const obj = task.toFirestoreObject();
        await setDoc(docRef, obj);
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTaskToFirestore", error);
        throw error;
      }
    },

    addTaskToState(task: TaskObject) {
      try {
        const taskExists = !!this.taskFromId(task.id);
        if (taskExists) return;
        this.tasks.push(task);
      } catch (error) {
        Logger.logError("Error in useTasksStore.removeTaskFromState", error);
        throw error;
      }
    },

    removeTaskFromState(task: TaskObject) {
      try {
        const taskIndex: number = this.tasks.findIndex(
          (t: TaskObject) => t.id == task.id
        );
        if (taskIndex >= 0) {
          this.tasks.splice(taskIndex, 1);
        }
      } catch (error) {
        Logger.logError("Error in useTasksStore.removeTaskFromState", error);
        throw error;
      }
    },

    saveTaskToState(task: TaskObject) {
      try {
        this.removeTaskFromState(task);
        this.addTaskToState(task);
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTaskToState", error);
        throw error;
      }
    },

    saveTasksToState(tasks: TaskObject[]) {
      try {
        for (const task of tasks) {
          this.saveTaskToState(task);
        }
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTasksToState", error);
        throw error;
      }
    },

    async saveTasksToFirestore(tasks: TaskObject[]) {
      try {
        const tasksCollectionRef = this.getUserTasksCollection();
        const chunks: TaskObject[][] = this.splitIntoChunks(tasks);

        for (const chunk of chunks) {
          const firestoreBatch = writeBatch(db);
          for (const task of chunk) {
            const firestoreTaskReference = doc(tasksCollectionRef, task.id);
            const fireStoreReadyTask = task.toFirestoreObject();
            firestoreBatch.set(firestoreTaskReference, fireStoreReadyTask);
          }
          await firestoreBatch.commit();
        }
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTasksToFirestore", error);
        throw error;
      }
    },

    async saveTasks(tasks: TaskObject[]) {
      if (!tasks) return;
      try {
        this.saveTasksToState(tasks);
        await this.saveTasksToFirestore(tasks);
      } catch (error) {
        Logger.logError("Error in useTasksStore.saveTasks", error);
        throw error;
      }
    },

    splitIntoChunks(tasks: TaskObject[]): TaskObject[][] {
      try {
        const result = [];
        for (let i = 0; i < tasks.length; i += Constants.MAX_BATCH_SIZE) {
          result.push(tasks.slice(i, i + Constants.MAX_BATCH_SIZE));
        }
        return result;
      } catch (error) {
        Logger.logError("Error in useTasksStore.splitIntoChunks", error);
        throw error;
      }
    },

    getUserTasksCollection() {
      try {
        const userStore = useUserStore();
        const userDocRef = userStore.userDocRef;
        return collection(userDocRef, "tasks");
      } catch (error) {
        Logger.logError("Error in useTasksStore.getUserTasksCollection", error);
        throw error;
      }
    },
    async repeatTask(task: TaskObject) {
      try {
        const nextTask = RepeatManager.makeRepeatedTask(task);
        await this.addTask(nextTask);
        await this.repeatSubtasksOf(task, nextTask);
      } catch (error: any) {
        Logger.logError("Error repeating task", error);
        throw error;
      }
    },

    async repeatSubtasksOf(fromTask: TaskObject, toTask: TaskObject) {
      try {
        const subtasks = this.subtasksOfTask(fromTask).filter(
          (sub: TaskObject) => {
            return sub.repeat && sub.repeat !== Constants.REPEAT_NONE;
          }
        );

        if (!subtasks) {
          return;
        }

        const fromDate: Date = fromTask.due ?? new Date();
        const toDate: Date = toTask.due ?? new Date();
        const dateChange = differenceInDays(toDate, fromDate);

        for (const sub of subtasks) {
          const newSub = new TaskObject({ ...sub });
          newSub.completed = undefined;
          const subFromDate = sub.due ?? new Date();
          newSub.due = addDays(subFromDate, dateChange);

          const fromStartDate = newSub.start;
          if (fromStartDate) {
            newSub.start = addDays(fromStartDate, dateChange);
          }
          newSub.id = generateUUID();
          newSub.parentId = toTask.id;

          await this.addTask(newSub);
          await this.repeatSubtasksOf(sub, newSub);
        }
      } catch (error: any) {
        Logger.logError("Error while repeating subtasks", error);
        throw error;
      }
    },

    removeTask(id: string) {
      this.removeTasks([id]);
    },

    async removeTasks(ids: string[]) {
      if (!ids) return;

      try {
        this.removeTasksFromStateWithIds(ids);

        let batch = writeBatch(db);
        let itemsInBatch = 0;
        const userStore = useUserStore();

        for (const id of ids) {
          itemsInBatch += 1;
          if (itemsInBatch >= Constants.MAX_BATCH_SIZE) {
            await batch.commit();
            batch = writeBatch(db);
            itemsInBatch = 1;
          }

          const userDocRef = doc(db, "users", userStore.user.uid);
          const collectionRef = collection(userDocRef, "tasks");
          const docRef = doc(collectionRef, id);

          batch.delete(docRef);
        }

        await batch.commit();
      } catch (error: any) {
        Logger.logError("Error in removeTasks", error);
        throw error;
      }
    },

    removeTasksFromStateWithIds(ids: string[]) {
      try {
        this.tasks = this.tasks.filter(
          (task: TaskObject) => !ids.includes(task.id)
        );
      } catch (error) {
        Logger.logError(
          "Error in useTasksStore.removeTasksFromStateWithIds",
          error
        );
        throw error;
      }
    },

    async purgeCompletedTasks() {
      try {
        const purging = this.tasksReadyToPurge;
        if (purging && purging.length > 0) {
          const ids: Array<string> = purging.map((d: TaskObject) => d.id ?? ""); // id's of tasks to purge
          return await this.removeTasks(ids); // remove them
        }
      } catch (error) {
        Logger.logError("Error in useTasksStore.purgeCompletedTasks", error);
        throw error;
      }
    },

    unsubscribeFirestore() {
      try {
        if (firebaseTasksUnsubscribe) {
          firebaseTasksUnsubscribe();
          this.isFirestoreSubscribed = false;
        }
      } catch (error) {
        Logger.logError("Error in useTasksStore.unsubscribeFirestore", error);
        throw error;
      }
    },

    doImport(file: File) {
      try {
        let headers = [];
        const properties = new Array<string>();
        this.importedCount = 0;
        let rowsImported = 0;
        let chunksStarted = -1;
        let chunksFinished = 0;
        let fieldCount = 0;

        this.isImportParsing = true;
        this.importError = "";

        Papa.parse(file, {
          header: false,
          worker: false,

          chunk: async function (results, parser) {
            const store = useTasksStore();

            chunksStarted++;

            store.isImportProcessing = true;

            const data = results.data;
            const batch = writeBatch(db);
            parser.pause();

            for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
              if (chunksStarted === 0 && rowIndex === 0) {
                // this is the header row

                headers = data[rowIndex] as unknown as [];
                fieldCount = headers.length;
                for (let f = 0; f < fieldCount; f++) {
                  const incomingHeaderTitle = headers[f].toLowerCase();
                  switch (incomingHeaderTitle) {
                    case "title":
                    case "task":
                      properties.push("title");
                      break;
                    case "completed":
                      properties.push("completed");
                      break;
                    case "folder":
                      properties.push("folder");
                      break;
                    case "start":
                      properties.push("start");
                      break;
                    case "startdate":
                      properties.push("startDate");
                      break;
                    case "starttime":
                      properties.push("startTime");
                      break;
                    case "due":
                      properties.push("due");
                      break;
                    case "duedate":
                      properties.push("dueDate");
                      break;
                    case "duetime":
                      properties.push("dueTime");
                      break;
                    case "parentid":
                      properties.push("parentId");
                      break;
                    case "id":
                      properties.push("id");
                      break;
                    case "modified":
                      properties.push("modified");
                      break;
                    case "added":
                      properties.push("added");
                      break;
                    default:
                      properties.push(incomingHeaderTitle);
                  }
                }

                if (!properties.find((prop) => prop === "title")) {
                  store.importError = `Your import file must be in CSV format and its first row must have field headers including 'task' or 'title'`;
                  Sentry.captureException(
                    new Error(
                      `In doImport, no title in first row: ${JSON.stringify(
                        properties
                      )}`
                    )
                  );
                  return;
                }
                rowsImported++;
              } else {
                const temp: any = {};
                const foldersStore = useFoldersStore();
                const { folderFromTitle, addFolder } = foldersStore;
                const contextsStore = useContextsStore();
                const { contextFromTitle, addContext } = contextsStore;
                const goalsStore = useGoalsStore();
                const { goalFromTitle, addGoal } = goalsStore;

                const fields = data[rowIndex] as unknown as [];

                for (let f = 0; f < fields.length; f++) {
                  temp[properties[f]] = fields[f];
                }

                if (!temp.title) {
                  continue;
                }

                const task = new TaskObject({
                  title: temp.title,
                  id: generateUUID(),
                  imported: new Date(),
                });

                task.id = generateUUID();

                if (temp.note) {
                  const turndownService: TurndownService =
                    new TurndownService();
                  const md: string = turndownService.turndown(temp.note);
                  if (md) {
                    task.note = md;
                  } else {
                    task.note = temp.note;
                  }
                }

                if (temp.folder) {
                  const folder = folderFromTitle(temp.folder);
                  if (!folder) {
                    const newFolder = new FolderObject(temp.folder);
                    await addFolder(newFolder);
                    task.folderId = newFolder.id;
                  } else {
                    task.folderId = folder.id;
                  }
                }
                if (temp.context) {
                  const context = contextFromTitle(temp.context);
                  if (!context) {
                    const newContext = new ContextObject(temp.context);
                    await addContext(newContext);
                    task.contextId = newContext.id;
                  } else {
                    task.contextId = context.id;
                  }
                }
                if (temp.goal) {
                  const goal = goalFromTitle(temp.goal);
                  if (!goal) {
                    const newGoal = new GoalObject(temp.goal);
                    await addGoal(newGoal);
                    task.goalId = newGoal.id;
                  } else {
                    task.goalId = goal.id;
                  }
                }

                if (temp.start) {
                  task.start = new Date(temp.start);
                }

                if (temp.startDate) {
                  let startString = temp.startDate;
                  if (temp.startTime) {
                    startString = `${temp.startDate} ${temp.startTime}`;
                  }

                  task.start = new Date(startString);
                }

                if (temp.completed) {
                  task.completed = new Date(temp.completed);
                }

                if (temp.due) {
                  task.due = new Date(temp.due);
                }

                if (temp.dueDate) {
                  let dueString = temp.dueDate;
                  if (temp.dueTime) {
                    dueString = `${temp.dueDate} ${temp.dueTime}`;
                  }
                  task.due = new Date(dueString);
                }

                if (temp.repeat) {
                  task.repeat = temp.repeat;
                }

                if (temp.priority) {
                  switch (temp.priority) {
                    case "-1":
                    case "NEGATIVE":
                    case "Negative":
                    case "negative":
                      task.priority = Priority.Negative;
                      break;
                    case "0":
                    case "LOW":
                    case "Low":
                    case "low":
                      task.priority = Priority.Low;
                      break;
                    case "1":
                    case "MEDIUM":
                    case "Medium":
                    case "medium":
                      task.priority = Priority.Medium;
                      break;
                    case "2":
                    case "HIGH":
                    case "High":
                    case "high":
                      task.priority = Priority.High;
                      break;
                    case "3":
                    case "TOP":
                    case "Top":
                    case "top":
                      task.priority = Priority.Top;
                      break;
                  }
                }

                if (temp.star) {
                  task.isStarred = temp.star === "Yes";
                }

                if (temp.parentID) {
                  task.parentId = temp.parentID;
                }

                if (!temp.title) {
                  store.importError = `Line ${
                    rowsImported + 1
                  } of the import file has no title or task field.`;
                  return;
                }

                const userStore = useUserStore();
                if (!userStore.user?.uid) {
                  store.importError =
                    "You must be logged in if you want to import tasks";
                  return false;
                }

                task.modified = task.added;

                if (store.tasks.length > userStore.tasksLimit) {
                  store.importError = `Import can't continue because you would exceed your limit of  ${userStore.tasksLimit} tasks`;
                  parser.abort();
                  store.importAborted = true;
                  return;
                }

                const foundIndex = store.tasks.findIndex(
                  (t: TaskObject) => t.id == task.id
                );

                const found = foundIndex >= 0;
                const needsImport = true;

                if (needsImport) {
                  if (found) {
                    store.tasks.splice(foundIndex, 1);
                  }
                  task.added = task.added ?? new Date();
                  task.modified = task.modified ?? task.added;

                  task.imported = new Date();

                  store.tasks.push(task);

                  const userDocRef = doc(db, "users", userStore.user.uid);

                  if (!userDocRef) {
                    store.importError =
                      "Unable to import as your account details are not available";
                    parser.abort();
                    store.importAborted = true;
                    return false;
                  }

                  const obj = task.toFirestoreObject();

                  const collectionRef = collection(userDocRef, "tasks");
                  const docRef = doc(collectionRef, obj.id);
                  batch.set(docRef, obj);

                  store.importedCount++;
                }
                rowsImported++;
              }
            }

            await batch.commit();
            chunksFinished++;
            parser.resume();
            store.isImportProcessing = chunksStarted > chunksFinished;
          },
          complete: async (/*results, file, meta*/) => {
            const store = useTasksStore();
            store.isImportParsing = false;
            store.isImportProcessing = false;
            store.refreshTaskLists();
          },
          error: async (err: Error | any) => {
            Logger.logError(`doImport Parser reports an error`, err);
            const store = useTasksStore();
            store.importError = err.message ?? err;
            store.importAborted = true;
            store.isImportProcessing = false;
          },
        });
      } catch (error) {
        Logger.logError("Error in useTasksStore.doImport", error);
        const store = useTasksStore();
        store.isImportProcessing = false;
        throw error;
      }
    },

    refreshTaskLists() {
      this.tasks = this.tasks;
    },

    makeExport(): string {
      try {
        const store = useTasksStore();

        const foldersStore = useFoldersStore();
        const { folderFromId } = foldersStore;
        const contextsStore = useContextsStore();
        const { contextFromId } = contextsStore;
        const goalsStore = useGoalsStore();
        const { goalFromId } = goalsStore;
        const tasksForExport = store.tasks.map((task) => ({
          id: task.id,
          title: task.title,
          completed: task.completed ? formatISO(task.completed) : undefined,
          added: task.added ? formatISO(task.added) : undefined,
          modified: task.modified ? formatISO(task.modified) : undefined,
          parentID: task.parentId,
          folder: task.folderId ? folderFromId(task.folderId)?.title ?? "" : "",
          context: task.contextId
            ? contextFromId(task.contextId)?.title ?? ""
            : "",
          goal: task.goalId ? goalFromId(task.goalId)?.title ?? "" : "",
          start: task.start ? formatISO(task.start) : undefined,
          due: task.due ? formatISO(task.due) : undefined,
          repeat: task.repeat,
          priority: task.priority,
          status: task.status,
          star: task.isStarred,
          note: task.note,
        }));

        return Papa.unparse(tasksForExport, {
          header: true,
        });
      } catch (error) {
        Logger.logError("Error in useTasksStore.makeExport", error);
        throw error;
      }
    },
  },
});
